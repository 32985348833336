/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-require-imports */
const getLocaleFromEdition = (edition) => {
    switch (edition) {
        case "argentina":
            return "es-ar";
        case "australia":
            return "en-au";
        case "brazil":
            return "pt-br";
        case "canada":
            return "en-ca";
        case "germany":
            return "de-de";
        case "hong_kong":
            return "en-hk";
        case "india":
            return "en-in";
        case "japan":
            return "ja-jp";
        case "malaysia":
            return "en-my";
        case "mexico":
            return "es-mx";
        case "new_zealand":
            return "en-nz";
        case "singapore":
            return "en-sg";
        case "south_korea":
            return "ko-kr";
        case "spain":
            return "es-es";
        case "thailand":
            return "th-th";
        case "united_kingdom":
            return "en-gb";
        case "usa":
            return "en-us";
        case "usa_spain":
            return "es-us";
        case "vietnam":
            return "vi-vn";
        default:
            return "en-us";
    }
};

const initLocale = (locale) => {
    const i18nApp = require("gettext.js").default;
    const i18n = i18nApp();
    const jsonData = require(`../../../locale/messages-${locale}.json`);
    i18n.loadJSON(jsonData);
    i18n.setLocale(locale);

    const dayjs = require("dayjs");
    try {
        require(`dayjs/locale/${locale}.js`);
        dayjs.locale(locale);
    } catch {
        const shortLocale = locale.split("-")[0];
        console.log(
            `dayjs: locale ${locale} not found, falling back to locale ${shortLocale}`,
        );
        require(`dayjs/locale/${shortLocale}.js`);
        dayjs.locale(shortLocale);
    }

    return [i18n, dayjs];
};

const formatPublishedOn = (
    publishedOn,
    i18n,
    dayjs,
    lessThanOneDay = false,
    longDate = false,
    dateFormatOverride = null,
) => {
    const locale = i18n.getLocale();
    const publishedDate = dayjs(publishedOn);
    const currentDate = dayjs();

    const diffInHours = currentDate.diff(publishedDate, "hour");
    let diffInMinutes = currentDate.diff(publishedDate, "minute");

    let dateFormat = "";
    let returnDate = "";
    let returnTime = "";

    try {
        // Date logic
        if (lessThanOneDay && diffInMinutes < 60) {
            // We still display "1 minute ago" even when the article was just published
            diffInMinutes = Math.max(diffInMinutes, 1);

            if (longDate) {
                const localisedString = i18n.ngettext(
                    "%(count)s minute ago",
                    "%(count)s minutes ago",
                    diffInMinutes,
                );
                returnDate = localisedString.replace(
                    "%(count)s",
                    diffInMinutes,
                );
            } else {
                returnDate = `${diffInMinutes}m`;
            }
        } else if (lessThanOneDay && diffInHours < 24) {
            if (longDate) {
                const localisedString = i18n.ngettext(
                    "%(count)s hour ago",
                    "%(count)s hours ago",
                    diffInHours,
                );
                returnDate = localisedString.replace("%(count)s", diffInHours);
            } else {
                returnDate = `${diffInHours}hr`;
            }
        } else {
            if (longDate) {
                if (dateFormatOverride) {
                    returnDate = publishedDate.format(dateFormatOverride);
                } else {
                    const dateObj = new Date(publishedOn);
                    const dateFormatter = new Intl.DateTimeFormat(locale, {
                        dateStyle: "long",
                        calendar: "gregory",
                    });
                    returnDate = dateFormatter.format(dateObj);
                }
            } else {
                switch (locale) {
                    case "en-us":
                    case "en-ca":
                        dateFormat = "MM-DD-YYYY";
                        break;
                    case "ja-jp":
                    case "ko-kr":
                        dateFormat = "YYYY-MM-DD";
                        break;
                    default:
                        dateFormat = "DD-MM-YYYY";
                }

                returnDate = publishedDate.format(dateFormat);
            }
        }

        // Time logic
        if (longDate) {
            const dateObj = new Date(publishedOn);
            const currentTimeZone =
                Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timeFormatter = new Intl.DateTimeFormat(locale, {
                hour: "numeric",
                minute: "numeric",
                timeZone: currentTimeZone,
                timeZoneName: "short",
                hourCycle: "h12",
            });
            returnTime = timeFormatter.format(dateObj);
        }
    } catch (error) {
        console.error("Parsing error:", error.message);
        // Fall back to blank values in case of error
        returnDate = "";
        returnTime = "";
    }

    return [returnDate, returnTime];
};

export const publishedShortDateDifference = () => {
    const published = document.querySelector(".published-datetime-short");

    if (published) {
        const tsnEdition = published.getAttribute("data-tsnedition");
        const publishedOnTimestamp = published.getAttribute("datetime");
        const locale = getLocaleFromEdition(tsnEdition);
        const [i18n, dayjs] = initLocale(locale);
        const [formattedDate, _] = formatPublishedOn(
            publishedOnTimestamp,
            i18n,
            dayjs,
        );
        published.innerHTML = formattedDate;
    }
};

export const publishedLongDateDifference = () => {
    const published = document.querySelector(".published-datetime-long");

    if (published) {
        const tsnEdition = published.getAttribute("data-tsnedition");
        const publishedOnTimestamp = published.getAttribute("datetime");
        const dateFormatOverride = published.getAttribute(
            "date-format-override",
        );
        const locale = getLocaleFromEdition(tsnEdition);
        const [i18n, dayjs] = initLocale(locale);
        const [formattedDate, formattedTime] = formatPublishedOn(
            publishedOnTimestamp,
            i18n,
            dayjs,
            true,
            true,
            dateFormatOverride,
        );
        let publishedDate = document.querySelector(
            ".published-datetime-long > .published-date",
        );
        if (publishedDate) {
            publishedDate.innerHTML = formattedDate;
        }
        let publishedTime = document.querySelector(
            ".published-datetime-long > .published-time",
        );
        if (publishedTime) {
            publishedTime.innerHTML = formattedTime;
        }
    }
};

export const updateListingPublishedFormats = () => {
    const locale = document.querySelector("html").lang
        ? document.querySelector("html").lang
        : "";
    const [i18n, dayjs] = initLocale(locale);

    const elements = document.querySelectorAll("time[datetime]");
    elements.forEach((element) => {
        const publishedOnTimestamp = element.getAttribute("datetime");
        const [formattedDate, _] = formatPublishedOn(
            publishedOnTimestamp,
            i18n,
            dayjs,
            true,
        );
        element.textContent = formattedDate;
    });
};
